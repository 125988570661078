import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useParams } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import { supportNumber, authTitle, logo, urlWebsite } from '../../config';
import { Container, Content, AnimatedDiv2, ErrorContainer } from './styles';
import Input from '../../components/Forms/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import Whatsapp from '../../components/Whatsapp';
import { useLanguage } from '../../hooks/Language';
import Loader from '../../components/Loader';
import { FaWhatsapp } from 'react-icons/fa';
import Logo from '../../components/Logo';
import { useConfiguration } from '../../hooks/Configuration';
import api from '../../services/api';



interface SignInData {
  email: string;
  password: string;
  name: string;
}

interface IParams {
  redirect?: string;
}

interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}

const SignIn: React.FC<IParams> = ({ redirect = '/' }) => {
  const history = useHistory();
  const [withPassword, setWithPassword] = useState(false);
  const { signIn, getTrackingIntegration } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { translate } = useLanguage();
  const [errorMessage, setErrorMessage] = useState<Record<string, string>>({});
  const [manutencao, setManutencao] = useState(false);
  const [notSubscribed, setNotSubscribed] = useState(false);
  const { projectHash, eventHash } = useParams<IParamsURL>();

  const { configuration, setConfiguration } = useConfiguration();

  const handleSubmit = useCallback(
    async (data: SignInData) => {
      setLoading(true);
      const getTracking = getTrackingIntegration();
      try {
        formRef.current?.setErrors({});

        const shape = withPassword
          ? {
            email: Yup.string()
              .required(`${translate('Email')} ${translate('obrigatório')}`),
            password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),
          }
          : {
            email: Yup.string()
              .required(`${translate('Email')} ${translate('obrigatório')}`)

          };

        const schema = Yup.object().shape(shape);
        const { email: email_trim } = data;
        data.email = email_trim.toLowerCase().trim();

        await schema.validate(data, { abortEarly: false });

        let { email, name, password } = data;

        email = email?.trim().toLowerCase();

        const signed = await signIn({ email, password, name, product_id: configuration?.current_event_id_response?.product_id, project_id: projectHash, event_id: eventHash ? eventHash : '' });

        if (signed && signed?.subscribed === 'yes') {
          window.location.href = projectHash === 'simposio-dasa-tmo' ? `/app/${projectHash}/dashboard` : redirect !== '/' ? `/dashboard/${redirect}` : (projectHash ? `/app/${projectHash}/dashboard/` : '/projects');

        }
        else if (signed?.subscribed === 'no') {

          const checkUser = await api.post(`/check-subscriber`, { tracking: getTracking, email: data.email, project_id: projectHash, event_id: eventHash || '', product: configuration?.current_event_id_response?.product_id });

          if (checkUser) {
            setLoading(false);
            window.location.href = projectHash === 'simposio-dasa-tmo' ? `/app/${projectHash}/dashboard` : redirect !== '/' ? `/dashboard/${redirect}` : (projectHash ? `/app/${projectHash}/dashboard/` : '/projects');

          }


        }


        //  window.location.href = redirect !== '/' ? `/dashboard/${redirect}` : (projectHash ? `/app/${projectHash}/dashboard/` : '/projects');
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setLoading(false);

        const { status } = err?.response;

        if (status >= 500) {

          setErrorMessage({ title: translate('Um momento...'), description: translate('Estamos com alta demanda de pedidos de acesso. <br/><br/>Por favor refaça seu login em 1 minuto para acessar.') })
          setTimeout(() => {
            setErrorMessage({});
          }, 60000)

        }
        else {

          setErrorMessage({ title: '', description: translate('Olá! Não localizamos o e-mail informado em nossa base de dados. Por favor certifique-se de que está correto ou inscreva-se!') })
          setTimeout(() => {
            setErrorMessage({});
          }, 5000)

        }




      }
    },
    [signIn, addToast, history],
  );

  return (<>
    {loading && <Loader message="Autenticando seu acesso ..." />}
    <Container>
      <Content>
        <AnimatedDiv2 className='shadowBox'>

          {manutencao ? <div className="onlineMessage ">

            <h2 className="defaultMarked"><div />{translate('Estamos em manutenção')}</h2>
            <p style={{ color: '#333', textAlign: 'center', marginTop: '20px' }}>
              {translate(
                'Voltamos em breve!',
              )}
            </p><br />
          </div> : <Form className="form" ref={formRef} onSubmit={handleSubmit}>

            <aside style={{ width: '100%', maxWidth: '350px', margin: '20px auto' }}>
              {projectHash && configuration.logo ? <img src={`${urlWebsite}/${configuration.logo}`} style={{ width: '250px', margin: '10px auto' }} /> : <Logo />}
            </aside>
            <h2 style={{ margin: '20px auto' }} className="titleElement">{translate('ACESSAR')}</h2>

            <Input name="email" placeholder={translate('E-mail')} />
            {withPassword && <Input

              name="password"
              type="password"
              placeholder={translate('Senha')}
            />}

            {errorMessage && (errorMessage.title || errorMessage.description) && <ErrorContainer>
              <h2>{errorMessage.title}</h2>
              <p dangerouslySetInnerHTML={{ __html: errorMessage.description }} />
            </ErrorContainer>}

            {notSubscribed ? <ErrorContainer>
              <h2 style={{ color: '#fff' }}>{translate("Sua inscrição neste evento não foi identificada")}</h2>
              <p style={{ color: '#fff' }}>{translate("Clique no botão abaixo para se inscrever")}</p>
              <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Link style={{ color: '#fff', fontWeight: 'bold', cursor: 'pointer' }} to={eventHash ? `/app/${projectHash}/event/${eventHash}/inscricao` : `/app/${projectHash}/inscricao`}><button className='defaultButton'> {translate("INSCREVA-SE")}</button> </Link>

              </aside>
            </ErrorContainer> : <></>}
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <button className='defaultButton' style={{ margin: '0px auto', marginTop: '40px' }} type="submit">{translate('Entrar')}</button>
            </aside>
            {withPassword && <> <p style={{ margin: '10px 0px' }}>{translate("Caso tenha dificuldade em logar, solicite uma nova senha.")}</p>
              <Link style={{ color: '#333' }} to="/recuperar_senha"><Button>{translate('Esqueci minha senha')}</Button>

              </Link></>}
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <Link style={{ color: '#555', fontWeight: 'bold', cursor: 'pointer' }} to={eventHash ? `/app/${projectHash}/event/${eventHash}/inscricao` : `/app/${projectHash}/inscricao`}> {translate("QUERO ME INSCREVER")} </Link>

            </aside>

            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}><Whatsapp
              number={supportNumber}
              text={`${translate(`Olá, estou no ${authTitle}`)}`}
              message={translate('Suporte Técnico')}
            />

            </aside>
          </Form>}
        </AnimatedDiv2>
      </Content>
    </Container></>
  );
};
export default SignIn;
